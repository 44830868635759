import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import Cloud from "@material-ui/icons/Cloud";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SettingsIcon from '@material-ui/icons/Settings';
import CodeIcon from '@material-ui/icons/Code';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class CompetencesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Focus Areas</h2>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer justify={"center"}>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Artificial Intelligence & ML"
                description="OpenCV, Intel OpenVINO, NVIDIA CUDA, Jupyter Notebook, Big Data"
                icon={Chat}
                iconColor="warning"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Cloud"
                description="Microsoft Azure, Amazon AWS, Google Cloud Platform"
                icon={Cloud}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Frontend"
                description="Angular, ReactJS"
                icon={Fingerprint}
                iconColor="danger"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Backend"
                description="Java, Spring Framework, Python, Docker"
                icon={CodeIcon}
                iconColor="danger"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="IoT"
                description="MQTT, Smart Buildings, HVAC Systems, Embedded Linux"
                icon={LocationCityIcon}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="DevOps"
                description="Git, Jenkins, Continuous integration, Continuous deployment"
                icon={SettingsIcon}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(CompetencesSection);
